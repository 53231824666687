<template>
  <div class="app-box">
    <div>
      <template v-if="orderType">
        <div class="coloeTxt" v-if="time">
          <i class="iconfont icon-shijian"></i>等待支付
          <div class="timeUl">
            <p class="iconbsn">
              剩余:<van-count-down class="coloerihe" :time="time" />
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="coloeTxt">
          <i class="iconfont icon-shijian"></i>订单超时
        </div>
      </template>
      <!-- end -->
      <div class="UlBox">
        <div class="li">
          <p class="titleH3">公司账户信息</p>
          <div class="mapeBox">
            <p class="pTitle">公司名称</p>
            <p class="HTitle" v-if="companyInfo[0]">
              {{ companyInfo[0].conf_value }}
            </p>
            <p
              class="copy"
              v-if="companyInfo[0]"
              @click="copyUrl(companyInfo[0].conf_value)"
            >
              复制
            </p>
          </div>
          <div class="mapeBox">
            <p class="pTitle">银行账号</p>
            <p class="HTitle" v-if="companyInfo[1]">
              {{ companyInfo[1].conf_value }}
            </p>
            <p
              class="copy"
              v-if="companyInfo[1]"
              @click="copyUrl(companyInfo[1].conf_value)"
            >
              复制
            </p>
          </div>
          <div class="mapeBox">
            <p class="pTitle">开户行</p>
            <p class="HTitle" v-if="companyInfo[2]">
              {{ companyInfo[2].conf_value }}
            </p>
            <p
              class="copy"
              v-if="companyInfo[2]"
              @click="copyUrl(companyInfo[2].conf_value)"
            >
              复制
            </p>
          </div>
        </div>
      </div>
      <!-- end -->
      <div class="TopBox">
        <p class="titleH3 titleH31">订单信息</p>
        <div class="left">
          <img src="../../assets/img/operateOper.png" alt="" />
        </div>
        <div class="right">
          <p class="rightText">
            <span class="spanColor">签约城市:</span
            >{{
              listArr.province == null
                ? "全国"
                : listArr.province + listArr.city + listArr.area
            }}
          </p>
          <p class="rightText">
            <span class="spanColor">合同编号:</span>{{ listArr.contract_sn }}
            <span class="fr" @click="copyUrl(listArr.contract_sn)">复制</span>
          </p>
          <p class="rightText_p">
            <span class="spanColor">{{ listArr.truename }}:</span
            >{{ listArr.phone }}
          </p>
        </div>
        <p class="service">
          订单编号:{{ listArr.sn_code
          }}<span class="fr" @click="copyUrl(listArr.sn_code)">复制</span>
        </p>
        <p class="titleirmb">
          订单金额:<span class="rmbColor">¥{{ listArr.price }}</span>
        </p>
      </div>
      <!-- end -->
      <div class="UlBox">
        <div class="li">
          <p class="titleH3">凭证信息</p>
          <div class="mapeBox">
            <div class="imgFor" v-for="(item, index) in arrer" :key="index">
              <img class="imgWIdhr" :src="item" @click="imgClike(index)" />
              <div class="meoae" @click="deleteClick(index)">
                <van-icon class="meoae1" name="cross" />
              </div>
            </div>
            <van-uploader multiple :after-read="afterRead" v-show="moejrIs" />
          </div>
          <div class="pokeoBox">
            <van-button type="info" block @click="nextForm">提交</van-button>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->
    <van-popup
      v-model="show"
      round
      style="width: 80%"
      :close-on-click-overlay="false"
    >
      <div class="poperBox">
        <img src="../../assets/img/home_submit.png" alt="" />
        <p class="sucerr">提交成功</p>
        <van-button class="bordkoae" type="info" block @click="Jump"
          >去填写运营入驻信息表</van-button
        >
      </div>
    </van-popup>
    <!-- end  -->
    <van-popup
      v-model="show1"
      round
      style="width: 90%"
      :close-on-click-overlay="false"
    >
      <div>
        <CheckOrder
          :listArr="listArr"
          :imgArr="arrer"
          @second="recordProgress"
        ></CheckOrder>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { uploadImage, wwyInfo, payment, getOrdersInfo } from "@/request/api";
import CheckOrder from "@/components/CheckOrder/CheckOrder.vue";
import { ImagePreview } from "vant";
import { Toast } from "vant";
import moment from "moment";
export default {
  components: {
    CheckOrder,
  },
  data() {
    return {
      time: "",
      show1: false,
      arrer: [],
      show: false,
      companyInfo: [],
      listArr: [],
      orderType: true,
      moejrIs: true,
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      wwyInfo().then((res) => {
        this.companyInfo = res.data;
      });
      let data = {
        id: this.$route.query.id,
      };
      getOrdersInfo(data).then((res) => {
        this.listArr = res.data;
        let diff = moment().diff(moment(this.listArr.create_date));
        if (diff >= 1800000) {
          this.orderType = false;
          return;
        } else {
          this.orderType = true;
          let dataTime = 1800000 - diff;
          this.time = dataTime;
        }
      });
    },
     //过滤时间
    timeDiff(timefor) {
      let diff = moment().diff(moment(timefor));
      if (diff >= 1800000) {
        return false;
      } else {
        // this.orderType = true;
        let dataTime = 1800000 - diff;
        return dataTime;
      }
    },
    afterRead(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
      if (file.length >= 2) {
        file.forEach((item) => {
          let pngIs = item.file.type === "image/jpeg";
          let jpegIs = item.file.type === "image/png";
          let isLt2M = item.file.size / 1024 / 1024 < 3;
          if (!pngIs && !jpegIs) {
            Toast("上传文件只能是 jpg、png格式!");
            return;
          }
          if (!isLt2M) {
            Toast("只能上传小于3M的图片");
            return;
          }
          let data = {
            image: item.content,
          };
          uploadImage(data).then((res) => {
            if (res.code != 1000) {
              Toast.fail(res.message);
              Toast.clear();
              return;
            }
            this.arrer = this.arrer.concat(res.data);
            if (this.arrer.length >= 5) {
              this.moejrIs = false;
              Toast.clear();
              return;
            } else {
              this.moejrIs = true;
            }
            Toast.clear();
          });
        });
      } else {
        const pngIs = file.file.type === "image/jpeg";
        const jpegIs = file.file.type === "image/png";
        const isLt2M = file.file.size / 1024 / 1024 < 3;
        if (!pngIs && !jpegIs) {
          Toast("上传文件只能是 jpg、png格式!");
          return;
        }
        if (!isLt2M) {
          Toast("只能上传小于3M的图片");
          return;
        }
        let data = {
          image: file.content,
        };
        uploadImage(data).then((res) => {
          if (res.code != 1000) {
            Toast.fail(res.message);
            Toast.clear();
            return;
          }
          this.arrer = this.arrer.concat(res.data);
          if (this.arrer.length >= 5) {
            this.moejrIs = false;
            Toast.clear();
            return;
          } else {
            this.moejrIs = true;
          }
          Toast.clear();
        });
      }
    },
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension2 = testmsg === "png";
      const isLt2M = file.size / 1024 / 1024 < 3;
      console.log(testmsg);
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 jpg、png格式!",
          type: "warning",
        });
      }
      if (!isLt2M) {
        this.$message({
          message: "上传文件大小不能超过 10MB!",
          type: "warning",
        });
      }
      return extension || (extension2 && isLt2M);
    },
    // 预览图片
    imgClike(index) {
      ImagePreview({
        images: this.arrer,
        startPosition: index,
      });
    },
    // 删除
    deleteClick(index) {
      this.arrer.splice(index, 1);
      if (this.arrer.length < 5) {
        this.moejrIs = true;
      }
    },
    copyUrl(url) {
      var domUrl = document.createElement("input");
      domUrl.value = url;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      Toast.success("复制成功");
    },
    recordProgress(e) {
      if (e == true) {
        setTimeout(() => {
          this.nextClick();
        }, 1500);
      }
    },
    nextForm() {
      if (this.arrer == "") {
        Toast.fail("请上传支付凭证");
        return;
      }
      this.show1 = true;
    },
    nextClick() {
      let data = {
        id: this.$route.query.id,
        payment_voucher: this.arrer,
      };
      payment(data).then((res) => {
        if (res.code != 1000) {
          Toast.fail(res.message);
          this.show1 = false;
          return;
        }
        this.show = true;
        this.show1 = false;
      });
    },
    Jump() {
      this.$router.push({
        path: "/SettledinformationOper",
        query: { id: this.$route.query.id },
      });
    },
  },
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background-color: #edf2f5ff;
  box-sizing: border-box;
  overflow: hidden;
}
.coloeTxt {
  color: #0e8affff;
  font-size: 0.43rem;
  font-weight: bold;
  text-align: center;
  padding: 0.4rem 0;
  padding-bottom: 0.2rem;
}
.coloeTxt .icon-shijian {
  margin-right: 0.1rem;
  font-size: 0.5rem;
  vertical-align: middle;
}
.timeBox {
  background: #0e8affff;
  display: inline-block;
  padding: 0.2rem 0.2rem;
  border-radius: 10px;
}
.timeUl {
  text-align: center;
  margin-top: 0.1rem;
}
.timeUl ::v-deep.van-count-down {
  display: inline-block;
}
.iconbsn {
  display: inline-block;
  background: RGBA(214, 231, 246, 1);
  color: #0e8aff;
  padding: 0.1rem 0.2rem;
  border-radius: 10px;
  font-size: 0.43rem;
}
.coloerihe {
  color: #0e8aff;
}
.UlBox {
  width: 100%;
  background: #fff;
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
  border-bottom: 5px solid #edf2f5ff;
}
.titleH3 {
  font-size: 0.43rem;
  position: relative;
  padding-left: 10px;
  font-weight: bold;
}
.titleH3::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  background: #0e8aff;
  border-radius: 10px;
}
.li .pTitle {
  color: #333b5c;
  font-size: 0.4rem;
  line-height: 1rem;
}
.li .HTitle {
  font-size: 0.43rem;
  color: #000;
  padding-right: 2rem;
}
.mapeBox {
  border-bottom: 1px solid #fff3f3f5;
  padding: 0.3rem 0;
  position: relative;
}
.mapeBox .copy {
  position: absolute;
  right: 0;
  bottom: 0.34rem;
  font-size: 0.43rem;
  color: #0e8aff;
}
.li .mapeBox:nth-last-child(1) {
  border: none;
}
.TopBox {
  box-sizing: border-box;
  padding: 0.4rem 0.3rem;
  background: #fff;
  overflow: hidden;
  padding-top: 0;
  border-bottom: 5px solid #edf2f5ff;
}
.TopBox .left {
  float: left;
}
.TopBox .right {
  margin-left: 2.3rem;
  font-size: 0.4rem;
  color: #000a33ff;
}
.TopBox .left img {
  width: 2rem;
}
.right .spanColor {
  color: #333b5cff;
}
.TopBox .service {
  font-size: 0.43rem;
  color: #333b5cff;
  line-height: 1rem;
  margin-top: 0.2rem;
  /* border-bottom: 1px solid #f3f3f5ff; */
}
.fr {
  float: right;
  color: #0e8aff;
}
.serviceText {
  font-size: 0.43rem;
  color: #333b5cff;
  margin-top: 0.3rem;
}
.titleBoxtxt {
  font-size: 0.4rem;
  margin-top: 0.2rem;
}
.positFlex {
  width: 100%;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  line-height: 1.5rem;
  box-sizing: border-box;
}
.positFlex .liFlex {
  flex: 4;
  font-size: 0.45rem;
}
.positFlex .liFlex p {
  padding: 0 0.3rem;
}
.positFlex .liFlex1 {
  flex: 2;
  text-align: center;
  font-size: 0.45rem;
  background: #0e8affff;
  color: #fff;
}
.colornkae {
  color: #fb4a51ff;
}
.titleH31 {
  background: #fff;
  padding: 0.3rem;
}
.titleirmb {
  text-align: right;
  background: rgba(242, 249, 255, 1);
  border-radius: 10px;
  line-height: 1.5rem;
  font-size: 0.43rem;
  padding-right: 0.5rem;
}
.rmbColor {
  color: #fb4a51ff;
}
.imgWIdhr {
  width: 80px;
  height: 80px;
  margin-right: 0.2rem;
}
.imgFor {
  position: relative;
  float: left;
  overflow: hidden;
  margin-bottom: 0.2rem;
}
.imgFor .meoae {
  position: absolute;
  top: 0;
  right: 8px;
  background: rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 5px;
  width: 0.3rem;
  text-align: center;
}
.imgFor .meoae1 {
  font-size: 0.2rem;
  color: #fff;
}

.pokeoBox ::v-deep.van-button {
  border-radius: 10px;
}
.poperBox {
  padding: 0.5rem 0.5rem;
}
.poperBox img {
  width: 120px;
  height: 100px;
  margin: auto;
}
.sucerr {
  text-align: center;
  font-size: 0.4rem;
  color: #666c85ff;
  padding: 0.3rem 0;
}
::v-deep.van-button {
  border-radius: 10px;
}
.rightText {
  margin-bottom: 0.1rem;
}
.rightText_p {
  margin-top: 0.3rem;
}
</style>